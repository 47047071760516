import Footerbb from './Footerbb';
import Whatwedo from './Whatwedo';
import Main from './Main';
import React, { useRef, useEffect } from 'react';
import { HelmetProvider } from "react-helmet-async";

import { BrowserRouter as Router, Route, Routes,  } from 'react-router-dom';

const App: React.FC = () => {

  return (
    <div>
<HelmetProvider>
<Router>
      <div>
        <Routes>
          <Route path="/" element={<><Main /><Footerbb /></>} />
          {/* <Route path="/Whatwedo" element={<div><Main /><Whatwedo /><Footerbb /></div>} /> */}
          <Route path="/contact" element={<Whatwedo/>} />

        </Routes>
      </div>
    </Router>
</HelmetProvider>
    </div>
  );
};

export default App;